<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :post="post" :posts="posts" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/blog/posts/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { all, show, update } from '@/http/blog/posts';
import { convertFromURLToFile } from '@/constants/utils/file';
import { createBlogPostForm } from '@/constants/utils/form';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      lang: '',
      post: {
        id: '',
        title: '',
        title_image: null,
        quote: null,
        description_1: '',
        lang: '',
        description_2: '',
        description_3: '',
        seo_description: '',
        slug: null,
        site_images: [],
        categories: [],
      },
      posts: [],
      slug: this.$route.params.slug,
    };
  },

  async created() {
    await this.fetchPosts();
    await this.fetchPost();
  },

  watch: {
    $route() {
      this.slug = this.$route.params.slug;
      this.fetchPost();
    },
  },

  methods: {
    async fetchPosts() {
      try {
        const { data } = await all();

        this.posts = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił problem z pobraniem postów. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async fetchPost(params = {}) {
      try {
        const { data } = await show(this.slug, params);

        data.data.is_active = data.data.is_active === 1;
        this.post = data.data;

        this.$set(this.post, 'county_id', this.post.county);
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił problem z pobraniem postu. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async onSubmit(form) {
      const formData = createBlogPostForm(form);

      try {
        await update(this.slug, formData);

        this.showSuccessNotification('Dane zapisane', 'Post został zaktualizowany.');
        this.$router.push({ name: 'blog-posts-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił problem z edycją kategorii. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
